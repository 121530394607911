<template>
  <div id="cancel-newsletter-content">
    <form @submit.prevent="onSubmit">
      <div class="input-container">
        <font-awesome-icon :icon="['far', 'envelope']" size="2x" />
        <div class="field">
          <input
            v-model="email"
            type="email"
            name="email"
            autocomplete="email"
            required
            @focus="addClassToLabel"
            @blur="addClassToLabel"
          />
          <label for="email">Email</label>
        </div>
      </div>
      <button id="contact-submission-btn" class="button" type="submit">
        Unsubscribe
      </button>
    </form>
    <div v-if="submitted">
      <font-awesome-icon
        :icon="['fas', 'check-circle']"
        size="2x"
        v-if="!error"
      />
      <font-awesome-icon
        :icon="['fas', 'exclamation-circle']"
        size="2x"
        v-else
      />
      <p>{{ resMessage }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "unsubscribe-form",
  data() {
    return {
      email: null,
      error: null,
      submitted: false,
      resMessage: "",
    };
  },
  methods: {
    async onSubmit() {
      const res = await this.$http
        .post("contact/cancelNews", {
          json: {
            email: this.email,
          },
        })
        .json();
      if (res) {
        this.resMessage = res.message;
        this.error = res.error;
      } else {
        this.error = true;
        this.resMessage = "Unexpected error occured";
      }
      this.submitted = true;
    },
    addClassToLabel(e) {
      if (e.type === "focus") {
        document
          .querySelector(`label[for="${e.target.name}"]`)
          .classList.add("active");
      } else {
        if (e.target.value !== "") {
          //If it is not an empty String
          document
            .querySelector(`label[for="${e.target.name}"]`)
            .classList.add("active");
        } else {
          document
            .querySelector(`label[for="${e.target.name}"]`)
            .classList.remove("active");
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#cancel-newsletter-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h2 {
    height: 2rem;
  }

  .input-container {
    display: flex;
    margin: 2rem 0;
    background-color: #d8d8d8;
    padding: 1.5rem 1rem 0.8rem 1rem;
    border-radius: 20px;

    svg {
      padding: 10px 10px 10px 5px;
      color: #cccccc;
    }

    input {
      background: none;
      font-size: 18px;
      padding: 10px 10px 10px 5px;
      display: block;
      width: 100%;
      border: none;
      border-radius: 0;
      &:focus {
        outline: none;
      }
    }
  }

  .field {
    position: relative;
    display: flex;
    width: 100%;
    margin-left: 0.5rem;
  }

  label {
    color: #9c9ca8;
    font-size: 1rem;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 8px;
    top: 15px;
    transition: 300ms ease all;
    &.active {
      top: -14px;
      font-size: 0.8rem;
    }
  }

  #contact-submission-btn {
    text-align: center;
    background-color: #107e79;
    color: #f4f4f0;
    width: 80%;
    margin-bottom: 50px;

    &:hover {
      background-color: #0f706b;
    }
  }

  #error-cancel {
    color: red;
  }
}
</style>
