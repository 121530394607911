<template>
  <section id="unsubscribe-container">
    <h1>Unsubscribe</h1>
    <unsubscribe-form />
  </section>
</template>

<script>
import UnsubscribeForm from "@/components/UnsubscribePage/UnsubscribeForm";
export default {
  components: {
    UnsubscribeForm,
  },
};
</script>

<style lang="scss" scoped>
#unsubscribe-container {
  margin: 5rem auto;

  h1 {
    font-weight: normal;
    font-size: 3rem;
  }
}
</style>
